import React from 'react';
import { hydrateRoot } from 'react-dom/client';

export { render };

function render(pageContext) {
  const { Page, initialData, initialTideData, initialWindData, locationText, is404 } = pageContext;
  hydrateRoot(
    document.getElementById('root'),
    <React.StrictMode>
      <Page 
        initialData={initialData}
        initialTideData={initialTideData}
        initialWindData={initialWindData} // Pass initialWindData to Page
        locationText={locationText}
        is404={is404}
      />
    </React.StrictMode>
  );
}